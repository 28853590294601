import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import Button, { ButtonProps, ButtonClassKey } from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.gradient.main,
        transition: "all 1s ease-in",
        boxShadow: "0 0 3px 3px rgb(0, 0, 0, .5) inset",
        padding: "12px 24px",
        position: "relative",
        textTransform: "none",
        "&:after": {
            display: "none",
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: alpha(theme.palette.secondary.contrastText, .15),
            borderRadius: theme.shape.borderRadius,
        },
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
        "&:hover:after": {
            display: "block",
        },
    },
    text: {
        color: theme.palette.text.contrast,
    },
    disabled: {
        color: theme.palette.text.contrast,
    },
}));

interface IPrimaryButtonProps extends ButtonProps {
    target?: string;
}

export type PrimaryButtonClasses = ButtonClassKey;

export const PrimaryButton: React.FC<IPrimaryButtonProps> = ({
    children,
    className,
    classes = {},
    ...props
}) => {
    const styles = useStyles();

    return (
        <Button
            className={className}
            {...props}

            classes={{
                ...classes,
                root: clsx(styles.root, classes?.root),
                text: clsx(styles.text, classes?.text),
                disabled: clsx(styles.disabled, classes?.disabled),
            }}
        >
            {children}
        </Button>
    );
};