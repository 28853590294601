import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button, { ButtonProps, ButtonClassKey } from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.main}`,
        textTransform: "none",
        "&:hover": {
            backgroundColor: theme.palette.border.main,
        },
    },
    text: {
        color: theme.palette.primary.main,
    },
}));

interface ISecondaryButtonProps extends ButtonProps {}

export type SecondaryButtonClasses = ButtonClassKey;

export const SecondaryButton: React.FC<ISecondaryButtonProps> = ({
    children,
    className,
    classes = {},
    ...props
}) => {
    const styles = useStyles();

    return (
        <Button
            className={className}
            {...props}
            classes={{
                ...classes,
                root: clsx(styles.root, classes?.root),
                text: clsx(styles.text, classes?.text),
            }}
        >
            {children}
        </Button>
    );
};
